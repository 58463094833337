import React from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
const { isvUserAssignRolesList, isvUserAssignRoles } = iceStarkData.store.get('commonAction')?.isvUser;
const { getRoleSelections } = iceStarkData.store.get('commonAction')?.role;
import { NewSelect  } from 'ucode-components';
import '../index.scss';


// 分配角色
@connect(
    state => ({
        roleSelections: state.role.roleSelections,
        isvUserAssignRolesList: state.isvUser.isvUserAssignRolesList,
        isvUserAssignRolesLoaded: state.isvUser.isvUserAssignRolesLoaded,
    }),
    {
        getRoleSelections: getRoleSelections.REQUEST,
        getIsvUserAssignRolesList: isvUserAssignRolesList.REQUEST,
        setIsvUserAssignRoles: isvUserAssignRoles.REQUEST,
    }
)
class AssignRoles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.getRoleSelections('ISV');
    }

    componentDidUpdate(preProps) {
        if (this.props.visible == 'role' && this.props.visible !== preProps.visible) {
            if (this.props.record && this.props.record.eseId && this.props.record !== preProps.record) {
                this.props.getIsvUserAssignRolesList({ userId: this.props.userId, eseId: this.props.record.eseId });
            }
        }

        if (this.props.isvUserAssignRolesList && this.props.isvUserAssignRolesList !== preProps.isvUserAssignRolesList) {
            this.props.initialize({ roleIds: this.props.isvUserAssignRolesList[0] || '' });
        }

        if ((/2\d{2}$/.test(this.props.isvUserAssignRolesLoaded) && this.props.isvUserAssignRolesLoaded !== preProps.isvUserAssignRolesLoaded)) {
            this.props.handleVisible('', {}, true);
        }
    }

    // 提交
    handleSubmit = (data) => {
        this.props.setIsvUserAssignRoles({ userId: this.props.userId, eseId: this.props.record.eseId, roleIds: [data.roleIds] });
    }

    render() {
        const { visible, record = {}, handleVisible, handleSubmit, roleSelections } = this.props;
        return (
            <Dialog
                className="AssignRolesForm"
                title="分配角色"
                visible={visible == 'role'}
                onCancel={() => { handleVisible(); }}
                onOk={handleSubmit(this.handleSubmit)}
            >
                <FormGroupField
                    name="roleIds"
                    list={roleSelections}
                    component={NewSelect}
                    style={{ width: 330 }}
                    placeholder="请选择访问角色"
                />
            </Dialog >
        )
    }
}

const validate = (values, props) => {
    const errors = {};
    if (!values.roleIds) {
        errors.roleIds = '请选择访问角色';
    }

    return errors;
}

export default reduxForm({
    form: 'AssignRolesForm',
    initialValues: {

    },
    validate
})(AssignRoles)


